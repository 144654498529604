import {
  CircularArray,
  ENTITY_NAME_MAX_LENGTH,
  ENTITY_NAME_REGEX,
  EntityType,
} from '@customer-portal/constants';
import {
  FormControl,
  MenuItem,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Company/Page';
import Kebab from '../../../assets/img/svg/action_icons/More.svg';
import DownArrow from '../../../assets/img/svg/arrows/Arrow_Down_24.svg';
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
} from '../../../client/axios';
import Loader from '../../../components/CustomerPortal-Loader';
import { ENTITIES_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import SelectWithSearch from '../../Select-With-Search';
import TextLabel from '../../TextLabel';
import { StoreContext } from './../../../store';
import CompanyBodyUnauthorized from './Company-Body-Unauthorized';

const columnTitles: Array<{
  id: string;
  keyText: string;
  fallbackText: string;
}> = [
  {
    id: 'name',
    keyText: 'company_entity_management_th_entity_name',
    fallbackText: 'Entity Name',
  },
  {
    id: 'created-by',
    keyText: 'company_entity_management_th_created_by',
    fallbackText: 'Created By',
  },
  {
    id: 'last-updated-by',
    keyText: 'company_entity_management_th_last_updated_by',
    fallbackText: 'Last Updated By',
  },
  {
    id: 'total-resources',
    keyText: 'company_entity_management_th_total_resources',
    fallbackText: 'Total Resources',
  },
  {
    id: 'opener',
    keyText: '',
    fallbackText: '',
  },
];

const countsLabels: Record<string, { keyText: string; fallbackText: string }> = {
  licenses: {
    keyText: 'company_entity_management_label_licenses',
    fallbackText: 'Licenses',
  },
  hapoOrders: {
    keyText: 'company_entity_management_label_hapo_orders',
    fallbackText: 'License Provisioning Requests',
  },
  // Uncomment when we want to turn this on
  // collabDocuments: {
  //   keyText: 'company_entity_management_label_collab_docs',
  //   fallbackText: 'Collaboration Documents',
  // },
  regularSupportCases: {
    keyText: 'company_entity_management_label_incident_cases',
    fallbackText: 'Support Cases',
  },
  premiumSupportCases: {
    keyText: 'company_entity_management_label_premium_cases',
    fallbackText: 'Preventive Care Cases',
  },
};

const chipColors = CircularArray.from([
  { backgroundColor: '#0C3761' },
  { backgroundColor: '#11508D' },
  { backgroundColor: '#358ADF' },
  { backgroundColor: '#A244A1' },
  { backgroundColor: '#1C637A' },
]);

const CompanyBodyEntityManagement = () => {
  const baseClass = 'MyCompanyBody';
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();
  const {
    state, dispatch,
  } = useContext(StoreContext);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ entitiesWithAssignedCounts, setEntitiesWithAssignedCounts ] = useState<any[]>([]);
  const [ unassignedCounts, setUnassignedCounts ] = useState<any>({});
  const [ entities, setEntities ] = useState<any[]>([]);
  const [ isLoading, setIsLoading ] = useState(true);

  // Used for creating a new entity or renaming an existing entity
  const [ newEntityName, setNewEntityName ] = useState<string>('');
  const [ newEntityNameError, setNewEntityNameError ] = useState<string>('');

  // Keep track of entity that was clicked so we can scope the modal's info to that entity
  const [ selectedEntityIndex, setSelectedEntityIndex ] = useState<number>(-1);

  // Used for replacing an existing entity with another entity
  const [ replaceEntityDropdownItem, setReplaceEntityDropdownItem ] = useState({
    label: '-- None --',
    value: '',
  });

  const [ isCreateEntityModalOpen, setIsCreateEntityModalOpen ] = useState(false);
  const [ isRenameEntityModalOpen, setIsRenameEntityModalOpen ] = useState(false);
  const [ isDeleteEntityModalOpen, setIsDeleteEntityModalOpen ] = useState(false);
  const [ isReplaceEntityModalOpen, setIsReplaceEntityModalOpen ] = useState(false);

  const scrollTo = useRef<any>(null);

  const isViewEntityManagementAllowed = UserPermissionsHelper.isViewEntityManagementAllowed();
  const isEditEntityManagementAllowed = UserPermissionsHelper.isEditEntityManagementAllowed();

  const fetchEntitiesWithAssignedCounts = async () => {
    const response = await axiosGet<any[]>(
      ENTITIES_URL,
      state.companyId,
      await getAccessToken()
    );
    return response.data;
  };

  const fetchUnassignedCounts = async () => {
    const response = await axiosGet(
      `${ENTITIES_URL}/unassigned`,
      state.companyId,
      await getAccessToken()
    );
    return response.data;
  };

  const fetchData = async () => {
    try {
      const res = await Promise.all([
        fetchEntitiesWithAssignedCounts(),
        fetchUnassignedCounts(),
      ]);

      setEntitiesWithAssignedCounts(res[0]);
      setUnassignedCounts(res[1]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const newEntities = [
      ...entitiesWithAssignedCounts,
      unassignedCounts,
    ].map((entity) => ({
      ...entity,
      isOpen: false,
    }));
    setEntities(newEntities);
  }, [ entitiesWithAssignedCounts, unassignedCounts ]);

  useEffect(() => {
    const checkEntityNameExists = () => {
      const entityExists = entitiesWithAssignedCounts.some(
        (entity) => entity.name === newEntityName
      );
      return entityExists;
    };

    const validateEntityName = () => {
      const isValid = newEntityName.length <= ENTITY_NAME_MAX_LENGTH &&
        ENTITY_NAME_REGEX.test(newEntityName);
      return isValid;
    };

    const debounceRunValidations = _.debounce(() => {
      const entityExists = checkEntityNameExists();
      const isValid = validateEntityName();

      if (!newEntityName) {
        setNewEntityNameError('');
        return;
      }

      if (!isValid) {
        setNewEntityNameError(t(
          'company_entity_management_create_modal_invalid_name',
          'The name "{{entityName}}" is invalid!',
          { entityName: newEntityName }
        ));
        return;
      }

      if (entityExists) {
        setNewEntityNameError(t(
          'company_entity_management_create_modal_already_exists',
          `An entity with name '{{entityName}}' already exists!`,
          { entityName: newEntityName }
        ));
        return;
      }

      setNewEntityNameError('');
    }, 500);

    debounceRunValidations();
  }, [ newEntityName ]);

  const handleToggle = (e: any) => {
    const position = e.target.dataset.position;
    const newEntities = [ ...entities ];
    newEntities[position].isOpen = !newEntities[position].isOpen;

    setEntities(newEntities);
  };

  const handleKebabClose = () => {
    setAnchorEl(null);
  };

  const handleCloseCreateOrRenameEntityModal = () => {
    setIsCreateEntityModalOpen(false);
    setIsRenameEntityModalOpen(false);
    setNewEntityName('');
    setNewEntityNameError('');
  };

  const handleCloseReplaceEntityModal = () => {
    setIsReplaceEntityModalOpen(false);
    setReplaceEntityDropdownItem({
      label: '-- None --',
      value: '',
    });
  };

  const handleAttributeParentClick = (event: any) => {
    if (event.target.dataset.parent === 'kebab') {
      setAnchorEl(event.currentTarget);
      setSelectedEntityIndex(Number(event.target.dataset.position));
    }
  };

  const handleSubmitCreateEntity = async (event: any) => {
    try {
      const response = await axiosPost(
        ENTITIES_URL,
        state.companyId,
        await getAccessToken(),
        { name: newEntityName }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_create_success_msg',
          `Entity '{{entityName}}' has been created!`,
          { entityName: newEntityName }
        ),
      });

      setEntitiesWithAssignedCounts(
        [
          ...entitiesWithAssignedCounts,
          response.data,
        ].sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch (e) {
      console.error(e);

      const errorMessage = e.response.status === 409 ? t(
        'company_entity_management_create_modal_already_exists',
        'An entity with name {{entityName}} already exists!',
        { entityName: newEntityName }
      ) : t(
        'company_entity_management_create_error_msg',
        'Something went wrong, and the entity was not created! Please try again later!'
      );

      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    } finally {
      setIsCreateEntityModalOpen(false);
      setNewEntityName('');
    }
  };

  const handleSubmitRenameEntity = async (event: any) => {
    const entity = entitiesWithAssignedCounts[selectedEntityIndex];

    try {
      const response = await axiosPatch(
        `${ENTITIES_URL}/${entity._id}`,
        state.companyId,
        await getAccessToken(),
        { name: newEntityName }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_rename_success_msg',
          `Entity '{{entityName}}' has been renamed to '{{newEntityName}}'!`,
          {
            entityName: entity.name,
            newEntityName,
          }
        ),
      });

      setEntitiesWithAssignedCounts([
        ...entitiesWithAssignedCounts.slice(0, selectedEntityIndex),
        {
          ...entitiesWithAssignedCounts[selectedEntityIndex],
          name: newEntityName,
          updatedBy: {
            name: state.userName,
            email: state.userEmail,
          },
          updatedAt: new Date().toISOString(),
        },
        ...entitiesWithAssignedCounts.slice(selectedEntityIndex + 1),
      ]);
    } catch (e) {
      console.error(e);

      const errorMessage = e.response.status === 409 ? t(
        'company_entity_management_create_modal_already_exists',
        'An entity with name {{entityName}} already exists!',
        { entityName: newEntityName }
      ) : t(
        'company_entity_management_create_error_msg',
        'Something went wrong, and the entity was not created! Please try again later!'
      );

      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    } finally {
      setIsRenameEntityModalOpen(false);
      setNewEntityName('');
    }
  };

  const handleSubmitDeleteEntity = async (event: any) => {
    const entity = entitiesWithAssignedCounts[selectedEntityIndex];

    try {
      const response = await axiosDelete(
        `${ENTITIES_URL}/${entity._id}`,
        state.companyId,
        await getAccessToken()
      );

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_delete_success_msg',
          'Entity was deleted!'
        ),
      });

      setEntitiesWithAssignedCounts([
        ...entitiesWithAssignedCounts.slice(0, selectedEntityIndex),
        ...entitiesWithAssignedCounts.slice(selectedEntityIndex + 1),
      ]);
    } catch (e) {
      console.error(e);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_delete_error_msg',
          'Something went wrong, and the entity was not deleted! Please try again later!'
        ),
      });
    } finally {
      setIsDeleteEntityModalOpen(false);
      setSelectedEntityIndex(-1);
      setAnchorEl(null);
    }
  };

  const handleSubmitReplaceEntity = async (event: any) => {
    const entity = entitiesWithAssignedCounts[selectedEntityIndex];
    const newEntity = entitiesWithAssignedCounts.find((e) => e._id === replaceEntityDropdownItem.value);

    try {
      const response = await axiosPut(
        `${ENTITIES_URL}/${entity._id}`,
        state.companyId,
        await getAccessToken(),
        { id: replaceEntityDropdownItem.value }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_replace_success_msg',
          `Entity '{{entityName}}' has been replaced with '{{newEntityName}}'!`,
          {
            entityName: entity.name,
            newEntityName: replaceEntityDropdownItem.label,
          }
        ),
      });

      const newEntityWithAssignedCounts = [ ...entitiesWithAssignedCounts ];
      const newEntityIndex = newEntityWithAssignedCounts.findIndex((e) => e._id === newEntity._id);
      newEntityWithAssignedCounts[newEntityIndex] = {
        ...newEntity,
        updatedBy: {
          name: state.userName,
          email: state.userEmail,
        },
        updatedAt: new Date().toISOString(),
        counts: {
          collabDocuments: newEntity.counts.collabDocuments + entity.counts.collabDocuments,
          hapoOrders: newEntity.counts.hapoOrders + entity.counts.hapoOrders,
          licenses: newEntity.counts.licenses + entity.counts.licenses,
          regularSupportCases: newEntity.counts.regularSupportCases + entity.counts.regularSupportCases,
          premiumSupportCases: newEntity.counts.premiumSupportCases + entity.counts.premiumSupportCases,
        },
      };

      setEntitiesWithAssignedCounts([
        ...newEntityWithAssignedCounts.slice(0, selectedEntityIndex),
        ...newEntityWithAssignedCounts.slice(selectedEntityIndex + 1),
      ]);
    } catch (e) {
      console.error(e);

      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_entity_management_replace_error_msg',
          'Something went wrong, and the entity was not replaced! Please try again later!'
        ),
      });
    } finally {
      setIsReplaceEntityModalOpen(false);
      setReplaceEntityDropdownItem({
        label: '-- None --',
        value: '',
      });
    }
  };

  const renderAggregateDetails = (entity: any) => (
    <div
      className={`${baseClass}__License-Card-Info`}
      data-testid="LicenseCardInfo"
    >
      <div className={`${baseClass}__License-Card-Sub-License`}>
        <div
          className="MyCompanyBody__License-Column--details">
          {Object.entries(entity.counts).map(([ name, count ], i) => {
            if (
              (count as number <= 0) ||
              !(name in countsLabels)
            ) {
              return null;
            }

            const {
              keyText, fallbackText,
            } = countsLabels[name];
            const label = t(keyText, fallbackText);

            return (
              <styles.CountsChip
                key={i}
                label={`${label}: ${count}`}
                style={{ ...chipColors.at(i) }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  const generateEntityRows = () => {
    if (isLoading) {
      return (
        <div className={`${baseClass}__Loader`}>
          <Loader />
        </div>
      );
    }

    return entities.map((entity, i) => {
      const isSystemEntity = entity.type === EntityType.SYSTEM;
      const shouldShowAggregateDetails = !!entity.isOpen;
      const createdBy = isSystemEntity
        ? 'N/A'
        : entity.createdBy.name;
      const createdAt = isSystemEntity
        ? undefined
        : moment(entity.createdAt).format('YYYY-MM-DD');
      const lastUpdatedBy = isSystemEntity
        ? 'N/A'
        : entity.updatedBy.name;
      const lastUpdatedAt = isSystemEntity
        ? undefined
        : moment(entity.updatedAt).format('YYYY-MM-DD');
      const totalCounts = Object
        .entries(entity.counts)
        .reduce((acc, [ key, value ]) => {
          let count = value as number;
          if (!(key in countsLabels)) {
            count = 0;
          }
          return acc + count;
        }, 0);

      return (
        <div
          className={`${baseClass}__License-Card-Outer ${baseClass}__License-Card-V2`}
          key={i}
          data-testid="LicenseCard"
        >
          <div className={`${baseClass}__License-Card`}>
            <div className={`${baseClass}__License-Column--opener ${baseClass}__License-Column--opener-Desktop`}>
              <img
                onClick={handleToggle}
                id={entity._id}
                data-position={i}
                className={`${baseClass}__License-Column--Opener-Icon`}
                src={DownArrow}
                alt="Down Arrow"
                data-testid="ExpandLicenseButton"
              />
            </div>
            <div className={`${baseClass}__License-Column--name`}>
              <div className={`${baseClass}__License-Column--opener ${baseClass}__License-Column--opener-Mobile`}>
                <img
                  onClick={handleToggle}
                  id={entity._id}
                  data-position={i}
                  className={`${baseClass}__License-Column--Opener-Icon`}
                  src={DownArrow}
                  alt="Down Arrow"
                />
              </div>
              <div
                className={`${baseClass}__License-Column--Details-Text`}
              >
                <p className={`${baseClass}__License--Nickname Bold`} />
                <p className={`${baseClass}__License--Boon-Name Regular`}>
                  {entity.name}
                </p>
                <p className={`${baseClass}__License-Column-Serial Bold`} />
              </div>
            </div>
            <div className={`${baseClass}__License-Column--created-by`}>
              <p className={`${baseClass}__License-Column-Mobile-Title`}>
                {t('company_entity_management_th_created_by', 'Created By')}
              </p>
              <p className={`Small ${baseClass}__License-Column-Value`}>
                <span>{createdBy}</span>
                <br />
                <span>{!isSystemEntity && `at ${createdAt}`}</span>
              </p>
            </div>
            <div className={`${baseClass}__License-Column--last-updated-by`}>
              <p className={`${baseClass}__License-Column-Mobile-Title`}>
                {t('company_entity_management_th_last_updated_by', 'Last Updated By')}
              </p>
              <p className={`Small ${baseClass}__License-Column-Value`}>
                <span>{lastUpdatedBy}</span>
                <br />
                <span>{!isSystemEntity && `at ${lastUpdatedAt}`}</span>
              </p>
            </div>
            <div className={`${baseClass}__License-Column--total-resources`}>
              <p className={`${baseClass}__License-Column-Mobile-Title`}>
                {t('company_entity_management_th_total_resources', 'Total Resources')}
              </p>
              <p className={`Small ${baseClass}__License-Column-Value`}>
                {totalCounts}
              </p>
            </div>

            <div className={`${baseClass}__License-Menu`}>
              {(isEditEntityManagementAllowed && !isSystemEntity) ? (
                <img
                  src={Kebab}
                  alt="Menu Opener"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleAttributeParentClick}
                  data-parent="kebab"
                  data-position={i}
                  className={`${baseClass}__License-Menu-Opener`}
                  data-testid="MenuButton"
                />
              ) : (
                <div style={{ width: '24px' }} />
              )}
            </div>
          </div>
          {shouldShowAggregateDetails && renderAggregateDetails(entity)}
        </div>
      );
    });
  };

  if (!isViewEntityManagementAllowed) {
    return <CompanyBodyUnauthorized />;
  }

  return (
    <>
      <div
        ref={scrollTo}
        className={`${baseClass}__Licenses`}
        data-testid="CompanyBodyEntityManagement"
      >
        <h3
          className={`${baseClass}__Body-Heading Regular`}
          data-testid="Heading"
        >
          {t(
            'company_entity_management_title_text',
            `${state.companyName} Entity Management`,
            { companyName: state.companyName }
          )}
        </h3>
        <div className={`${baseClass}__Body-Subheading-Outer`}>
          <h5
            className={`${baseClass}__Body-Subheading Regular`}
            data-testid="SubHeading"
          >
            {t(
              'company_entity_management_description',
              'All your company entities are listed here. Rename, delete and move resources between entities. Please contact your team admin for any questions. System generated entities can not be deleted.'
            )}
          </h5>
          {isEditEntityManagementAllowed && (
            <Button
              onClick={() => setIsCreateEntityModalOpen(true)}
              text={t('company_entity_management_create_new_entity', 'Create New Entity')}
              className={`${baseClass}__Body-ButtonCSV`}
            />
          )}
          <Modal
            modalTitle="Standard Modal"
            modalDescription="Create Entity Modal"
            modalHeading={t(
              'company_entity_management_create_new_entity',
              'Create New Entity'
            )}
            innerClasses={`${baseClass}__Create-Entity-Modal`}
            handleClose={handleCloseCreateOrRenameEntityModal}
            open={isCreateEntityModalOpen}
            testId="CreateEntityModal"
          >
            <styles.EntityManagementModalInner className="Modal-Fields-Container">
              <FormControl className="">
                <TextField
                  variant={'outlined' as any}
                  onChange={(e) => setNewEntityName(e.target.value)}
                  error={false}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{ maxLength: ENTITY_NAME_MAX_LENGTH }}
                  placeholder={t(
                    'company_entity_management_create_entity_placeholder',
                    'Entity Name'
                  )}
                  defaultValue={newEntityName}
                />
                <span className="ValidationErrorMessage">
                  {newEntityNameError}
                </span>
                <Button
                  className={`${baseClass}__Button Button`}
                  text={t('company_entity_management_create_button', 'Create')}
                  onClick={handleSubmitCreateEntity}
                  disabled={!newEntityName || !!newEntityNameError}
                  isValid={!!newEntityName && !newEntityNameError}
                />
              </FormControl>
            </styles.EntityManagementModalInner>
          </Modal>
        </div>

        <div className={`${baseClass}__List`}>
          {isEditEntityManagementAllowed && (
            <styles.LicenseCardMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleKebabClose}
              onClick={handleKebabClose}
              className="Select__Outer"
              elevation={0}
              data-testid="EntityCardMenu"
            >
              <MenuItem
                onClick={() => setIsRenameEntityModalOpen(true)}
                className="UserCard__Menu-Option Select__Option"
                data-testid="RenameMenuItem"
              >
                {t('company_entity_management_context_menu_rename', 'Rename')}
              </MenuItem>
              <MenuItem
                onClick={() => setIsDeleteEntityModalOpen(true)}
                className="UserCard__Menu-Option Select__Option"
                data-testid="DeleteMenuItem"
              >
                {t('company_entity_management_context_menu_delete', 'Delete')}
              </MenuItem>
              <MenuItem
                onClick={() => setIsReplaceEntityModalOpen(true)}
                className="UserCard__Menu-Option Select__Option"
                data-testid="ReplaceMenuItem"
              >
                {t('company_entity_management_context_menu_replace', 'Replace')}
              </MenuItem>
            </styles.LicenseCardMenu>
          )}

          <Modal
            modalTitle="Standard Modal"
            modalDescription="Rename Entity Modal"
            modalHeading={t('company_entity_management_rename_modal_title', 'Rename Entity')}
            innerClasses={`${baseClass}__Rename-Entity-Modal`}
            handleClose={handleCloseCreateOrRenameEntityModal}
            open={isRenameEntityModalOpen}
            testId="RenameEntityModal"
          >
            <styles.EntityManagementModalInner className='Modal-Fields-Container'>
              <FormControl>
                <TextLabel label={t(
                  'company_entity_management_rename_modal_input_label',
                  `Rename '{{entityName}}'`,
                  { entityName: entities[selectedEntityIndex]?.name }
                )} />
                <TextField
                  variant={'outlined' as any}
                  onChange={(e) => setNewEntityName(e.target.value)}
                  error={false}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{ maxLength: ENTITY_NAME_MAX_LENGTH }}
                  placeholder={t(
                    'company_entity_management_create_entity_placeholder',
                    'Entity Name'
                  )}
                  defaultValue={newEntityName}
                />
                <span className="ValidationErrorMessage">
                  {newEntityNameError}
                </span>
                <Button
                  className={`${baseClass}__Button Button`}
                  text={t('company_entity_management_save_button', 'Save')}
                  onClick={handleSubmitRenameEntity}
                  disabled={!newEntityName || !!newEntityNameError}
                  isValid={!!newEntityName && !newEntityNameError}
                />
              </FormControl>
            </styles.EntityManagementModalInner>
          </Modal>
          <Modal
            modalTitle="Standard Modal"
            modalDescription="Delete Entity Modal"
            modalHeading={t('company_entity_management_delete_modal_title', 'Delete Entity')}
            innerClasses={`${baseClass}__Delete-Entity-Modal`}
            handleClose={() => setIsDeleteEntityModalOpen(false)}
            open={isDeleteEntityModalOpen}
            testId="DeleteEntityModal"
          >
            <styles.EntityManagementModalInner className='Modal-Fields-Container'>
              <FormControl>
                <p>
                  {t('company_entity_management_delete_modal_description', 'Are you sure you want to delete this entity? All existing records will be orphaned, and this operation can not be undone.')}
                </p>
                <Button
                  className={`${baseClass}__Button Button`}
                  text={t('company_entity_management_confirm_button', 'Confirm')}
                  onClick={handleSubmitDeleteEntity}
                  disabled={!isDeleteEntityModalOpen}
                  isValid={isDeleteEntityModalOpen}
                />
              </FormControl>
            </styles.EntityManagementModalInner>
          </Modal>
          <Modal
            modalTitle="Standard Modal"
            modalDescription="Replace Entity Modal"
            modalHeading={t('company_entity_management_replace_modal_title', 'Replace Entity')}
            innerClasses={`${baseClass}__Replace-Entity-Modal`}
            handleClose={handleCloseReplaceEntityModal}
            open={isReplaceEntityModalOpen}
            testId="ReplaceEntityModal"
          >
            <styles.EntityManagementModalInner className='Modal-Fields-Container'>
              <FormControl>
                <FormControl>
                  <SelectWithSearch
                    label={t(
                      'company_entity_management_replace_modal_description',
                      `Select the entity name to replace with. All existing records with '{{entityName}}' will be replaced. This operation can not be undone.`,
                      { entityName: entities[selectedEntityIndex]?.name }
                    )}
                    searchable
                    options={entitiesWithAssignedCounts
                      .filter((e) => e.name !== entities[selectedEntityIndex]?.name)
                      .map((e) => ({
                        label: e.name,
                        value: e._id,
                      }))}
                    onChange={setReplaceEntityDropdownItem}
                    value={replaceEntityDropdownItem}
                    data-testid="ReplaceEntityInput"
                  />
                </FormControl>
                <Button
                  className={`${baseClass}__Button Button`}
                  text={t('company_entity_management_confirm_button', 'Confirm')}
                  onClick={handleSubmitReplaceEntity}
                  disabled={!replaceEntityDropdownItem.value}
                  isValid={!!replaceEntityDropdownItem.value}
                />
              </FormControl>
            </styles.EntityManagementModalInner>
          </Modal>

          <div
            className={`${baseClass}__License-Table-Column-Title`}
            data-testid="LicenseTableHeaders"
          >
            {columnTitles.map((title, i) => (
              <div
                className={`${baseClass}__License-Column--${title.id}`}
                key={i}
              >
                <p>{t(title.keyText, title.fallbackText)}</p>
              </div>
            ))}
          </div>
          {generateEntityRows()}
        </div>
      </div>
    </>
  );
};

export default CompanyBodyEntityManagement;
